.mayo-clinic-banner {
    min-height: 450px;
    position: relative;

    .container {
        // position: absolute;
        // top: 80%;
        // left: 50%;
        // transform: translate(-50%, -80%);
        margin: auto;
    }

    @media screen and (max-width: 768px) {
        min-height: auto;
        height: 450px;
    }
}

.mayo-clinic-banner-text {
    max-width: 500px;
    color: #033950;
    font-size: 48px;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
