.search_kpj_section{
    img{
        max-width: none !important;
    }
    .seach_placeholder{
        background-color: #fff;
        padding: 20px 0px;

        .container{
            .search_form{
                display: flex;
                align-items: center;
                #gsearch{
                    font-size: 20px;
                    padding-left: 20px;
                    border: 0;
                    width: -webkit-fill-available;
                }
                #gsearch::-webkit-search-cancel-button{
                    
                }
            }
        }
    }

    .result_area{
        background-color: rgb(243, 245, 247);
        padding-top: 20px;

        .container{
            .result_text{
                color: black;
                font-size: 16px;
                margin-bottom: 20px;
            }
        }
    }
}

.our_speciality_centre_search_card, 
.video_search_card, 
.podcast_search_card, 
.articles_search_card, 
.campro_search_card,
.events_search_card{
    padding-top: 30px;
    padding-bottom: 60px;

    #category_title{
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 700;
        color: black;
    }

    .content_info, .content_info_mobile{
        background-color: #fff;
        padding: 15px;
        display: flex;
        border-radius: 15px;
        align-items: center;

        img{
            border-radius: 10px;
        }

        #content_title{
            color: black;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        #content_subs{
            line-height: 1.2;

            span{
                color: #39B5B2;
                font-weight: 600;
            }
        }

        .content_contact_container{
            display: flex;
            justify-content: space-between;

            .contact_1{
                display: flex;
                align-items: center;

                #text_1{
                    margin-bottom: 0;
                    line-height: normal;
                    color: black;
                }

                #text_2{
                    font-weight: 700;
                    font-size: 18px;
                    margin-bottom: 0;
                    color: gray;
                }
            }

            .contact_2{
                display: flex;
            }
        }

    }
}

.our_hospital_search_card{
    padding-top: 30px;
    padding-bottom: 60px;

    #category_title{
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 700;
        color: black;
    }

    .content_info, .content_info_mobile{
        background-color: #fff;
        padding: 15px;
        display: flex;
        border-radius: 15px;
        align-items: center;

        img{
            border-radius: 10px;
        }

        #content_title{
            color: black;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        #content_subs{
            line-height: 1.2;

            span{
                color: #39B5B2;
                font-weight: 600;
            }
        }

        .content_contact_container{
            display: flex;
            justify-content: space-between;

            .contact_1{
                display: flex;
                align-items: center;

                #text_1{
                    margin-bottom: 0;
                    line-height: normal;
                    color: black;
                }

                #text_2{
                    font-weight: 700;
                    font-size: 18px;
                    margin-bottom: 0;
                    color: gray;
                }
            }

            .emergency_line{
                align-items: center;
                display: flex;
            
                p{
                    background-color: #E98076;
                    padding: 6px 10px;
                    border-radius: 30px;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    color: white;
                }   
            }

            .contact_2{
                display: flex;
            }
        }

    }
}

.health_tourism_search_card{
    padding-top: 30px;
    padding-bottom: 60px;

    #category_title{
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 700;
        color: black;
    }

    .content_info{
        background-color: #fff;
        padding: 15px;
        display: flex;
        border-radius: 15px;
        align-items: center;

        img{
            border-radius: 10px;
        }

        #content_title{
            color: black;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        #content_subs{
            line-height: 1.2;

            span{
                color: #39B5B2;
                font-weight: 600;
            }
        }
    }
}

.health_packages_search_card{
    padding-top: 30px;
    padding-bottom: 60px;

    #category_title{
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 700;
        color: black;
    }

    .content_info{
        background-color: #fff;
        padding: 15px;
        display: flex;
        border-radius: 15px;
        align-items: center;
        width: fit-content;

        img{
            border-radius: 10px;
        }

        #package_name{
            color: black;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        #hosp_name{
            line-height: 1.2;
        }

        #package_price{
            color: #F06661;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;
        }
    }
}

.doctor_search_card{
    padding-top: 30px;
    padding-bottom: 60px;

    #category_title{
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 700;
        color: black;
    }
}

@media (max-width:1200px) {
    .our_speciality_centre_search_card, .our_hospital_search_card{
        .content_info{
            flex-direction: column;
            gap: 10px;
    
            .content_contact_container{
                flex-direction: column;
    
                .contact_1{
                    margin-bottom: 10px;
                }
    
                .contact_2{
                    justify-content: space-between;
                }
            }
        }
    }
}

@media (min-width: 481px) {
    .our_speciality_centre_search_card .content_info_mobile {
        display: none;
    }

    .our_hospital_search_card .content_info_mobile {
        display: none;
    }
}

@media (max-width: 480px) {
    //hide view
    .our_speciality_centre_search_card .content_info {
        display: none;
    }

    .our_hospital_search_card .content_info {
        display: none;
    }


    .our_speciality_centre_search_card, .our_hospital_search_card {
        .contact_2{
            flex-direction: column;
            gap: 10px;
        }
    }

    .our_speciality_centre_search_card {
        .content_info_mobile {
            .content_contact_container {
                display: block;

                .contact_2 {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                }
            }

            background-color: #fff;
            padding: 15px;
            display: block;
            border-radius: 15px;

            img {
                border-radius: 10px;
                width: 104px;
                height: 104px;
            }

            #content_title {
                color: black;
                font-size: 17px;
                font-weight: 700;
            }

            #content_subs {
                line-height: 1.2;
                font-size: 10px;
                line-height: 11.93px;
                font-weight: 400;
            }
        }
    } 

    .our_hospital_search_card {
        .content_info_mobile {
            .content_contact_container {
                display: block;

                .contact_2 {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                }
            }

            background-color: #fff;
            padding: 15px;
            display: block;
            border-radius: 15px;

            img {
                border-radius: 10px;
                width: 104px;
                height: 104px;
            }

            #content_title {
                color: black;
                font-size: 17px;
                font-weight: 700;
                line-height: 20px;
            }

            #content_subs {
                line-height: 1.2;
                font-size: 10px;
                line-height: 11.93px;
                font-weight: 400;
            }
        }
    } 
        

    .health_tourism_search_card{
        .content_info{
            flex-direction: row;
            gap: 10px;
            padding: 10px;

            img {
                width: 153px;
                border-radius: 10px;
                height: 153px;
            }

            #content_title {
                color: black;
                font-size: 15px;
                font-weight: 700;
                line-height: 18px;
            }

            #content_subs {
                font-size: 12px;
                line-height: 14px;
                color: #606060;
                margin-bottom: 0;
            }
        }
    }

    .health_packages_search_card{
        .content_info{
            flex-direction: row;
            gap: 10px;
            width: -webkit-fill-available;
            padding: 10px;

            .htsc_mobile{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 150px;
            }

            #package_name {
                color: black;
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 19px;
            }

            #hosp_name {
                line-height: 12px;
                font-size: 10px;
                font-weight: 400;
                color: black;
            }

            #package_price {
                color: #F06661;
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 19px;
            }
        }
    }

    .video_search_card {
        .content_info{
            padding: 10px;

            .vsc_mobile{
                flex-direction: column;

                .vsc_mobile_p{
                    font-size: 13px;
                    color: #666567;
                    line-height: 14.95px;
                }
            }

            #content_title {
                color: black;
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 19px;
            }

            #content_subs {
                line-height: 14.32px;
                font-size: 12px;
                font-weight: 400;
                color: #606060;
            }
        }
    } 

    .podcast_search_card {
        .content_info{
            padding: 10px;

            .vsc_mobile{
                flex-direction: column;

                .vsc_mobile_p{
                    font-size: 13px;
                    color: #666567;
                    line-height: 14.95px;
                }
            }

            #content_title {
                color: black;
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 19px;
            }

            #content_subs {
                line-height: 14.32px;
                font-size: 12px;
                font-weight: 400;
                color: #606060;
            }
        }
    } 

    .articles_search_card {
        .content_info{
            padding: 10px;

            .vsc_mobile{
                flex-direction: column;

                .vsc_mobile_p{
                    font-size: 13px;
                    color: #666567;
                    line-height: 14.95px;
                }
            }

            #content_title {
                color: black;
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 19px;
            }

            #content_subs {
                line-height: 14.32px;
                font-size: 12px;
                font-weight: 400;
                color: #606060;
            }
        }
    } 

    .campro_search_card, .events_search_card {
        .content_info{
            padding: 10px;

            .vsc_mobile{
                flex-direction: column;

                .vsc_mobile_p{
                    font-size: 13px;
                    color: #666567;
                    line-height: 14.95px;
                }
            }

            #content_title {
                color: black;
                font-size: 17px;
                font-weight: 700;
                margin-bottom: 5px;
                line-height: 19px;
            }

            #content_subs {
                line-height: 14.32px;
                font-size: 12px;
                font-weight: 400;
                color: #606060;
            }
        }
    } 
}